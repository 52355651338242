import newsData from "@/assets/data/news.json"

export function getTitleFromNews(name) {
  let title = null

  newsData.news.forEach((item) => {
    if (item.name === name) {
      title = item.data.content
    }
  })

  return title
}
